import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import * as Sentry from '@sentry/angular'

import { Analytics } from './app/utils/analytics'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.sentryURL) {
  Sentry.init({
    dsn: environment.sentryURL,
    release: environment.release_name + '-' + environment.version,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      // Capture console errors
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
      // Replay is commented out but can be enabled if needed
      // new Sentry.Replay(),
    ],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    // replaysOnErrorSampleRate: 1.0,
    // To set a uniform sample rate
    tracesSampleRate: 0.2,
    environment: environment.release_name,
    beforeSend(event, hint) {
      // Ignores Failed to fetch, performance metrics
      if (hint?.originalException instanceof Error){
        const message = hint.originalException.message
        const context: any = hint.captureContext
        
        if (message.includes('Failed to fetch (api.segment.io)')){
          return null
        }
      }
      
      // Ignores Network Error from userguiding.com
      if (event.exception?.values) {
        const exception = event.exception.values[0];
        if (
          exception.value?.includes('Network Error') &&
          (event.request?.url?.includes('userguiding.com') || 
           JSON.stringify(event).includes('userguiding.com'))
        ) {
          return null;
        }
      }
  
      return event // Send other errors normally
    }
  })
}

if (environment.production) {
  enableProdMode()
}

Analytics.load()

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err))
